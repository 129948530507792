import React from 'react'

const Footer = () => {
    return (
        <div>
            <br></br>
            <hr></hr>
            <p className="footer-text">Made with ♥️ by Sindhu<br></br>
            <br></br>
            <small>All rights reserved © 2024 Sindhu Bommali</small><br></br>
            </p>
        </div>
    )
}

export default Footer
